<template>
  <div
      @click="showFile"
      :style="{
      width: width,
      height: height,
      }"
      class="image-viewer ">
    <v-btn
        v-if="deleteAble"
        small
        absolute
        style="z-index: 99;top: 0;right: 0"
        fab
        @click.stop="onDeleteImage"
        icon
        color="error"
    >
      <v-icon>
        mdi-trash-can-outline
      </v-icon>
    </v-btn>
    <v-img
        class="ma-auto"
        :src="src"
    >
      <template v-slot:placeholder>
        <v-row
            class="fill-height ma-0"
            align="center"
            justify="center"
        >
          <v-progress-circular
              indeterminate
              color="primary"
          ></v-progress-circular>
        </v-row>
      </template>

    </v-img>
  </div>
</template>

<script>
export default {
  name: "ImageViewer",
  props: {
    width: {
      type: String,
      default: '60px'
    },
    height: {
      type: String,
      default: '60px'
    },
    src: {
      type: String,
      default: null,
    },
    deleteAble: {
      type: Boolean,
      default: false,
    }
  },

  computed: {

  },

  methods: {
    showFile() {
      window.open(this.src, '_blank')
    },
    onDeleteImage() {
      this.$emit('onDelete')
    }
  }
}
</script>

<style scoped>
.image-viewer {
  margin: 10px auto;
  position: relative;
  cursor: pointer;
  padding: 2px;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed #ccc;
  background-color: #eee;

}
</style>